import Vue from 'vue'
/* eslint-disable */
Vue.directive('animate-wrapper', {
  bind: (node, binding) => {
    const elem = Array.from(node.getElementsByClassName('js-animate-item'))
    const animateClass = binding.value.class
    const zero = 0
    if (zero < elem.length) {
      elem.forEach(el => {
        function offset(e) {
          const rect = e.getBoundingClientRect()
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop
          return {
            top: rect.top + scrollTop,
          }
        }
        function animOnScroll() {
          const animItemHeight = el.offsetHeight
          const animItemOffset = offset(el).top
          const animStart = 4

          let animItemPoint = window.innerHeight - animItemHeight / animStart
          if (animItemHeight > window.innerHeight) {
            animItemPoint = window.innerHeight - animItemHeight / animStart
          }

          if ((pageYOffset > animItemOffset - animItemPoint) && pageYOffset < (animItemOffset + animItemHeight)) {
            if (!el.classList.contains(animateClass)) {
              el.classList.add(animateClass)
            }
          } else {
            el.classList.remove(animateClass)
          }
        }
        window.addEventListener('scroll', animOnScroll)
      })
    }
  },
})
